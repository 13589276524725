/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
"use client";
import type { PriceFragmentFragment } from "@evolve-storefront/types/generated/graphql";
import { Image } from "@evolve-storefront/ui/base/Image/Image";
import { typography } from "@evolve-storefront/ui/base/Typography/typography";
import { cn } from "@evolve-storefront/ui/helpers/styles";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import type { Hit } from "instantsearch.js";
import { useLocale, useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import {
	Configure,
	Highlight,
	Hits,
	Index,
	InstantSearch,
	SearchBox,
	useHierarchicalMenu,
	usePagination,
	useSearchBox,
} from "react-instantsearch";
import { Link } from "~/components/Link";
import { Price } from "~/components/Price/Price";
// This is a temporary file for a demo, code is crap please don't copy it
const searchClient = algoliasearch(
	"YFGT6BQ6JS",
	"d1e2a65a9304b8243fc1a8f9680d50b5",
);
function HitComponent({ hit }: { hit: Record<string, unknown> }) {
	const locale = useLocale();
	const name = (hit.name as Record<string, string>)?.[locale] || "";
	const categories = ((hit.categoryPageId as string[])?.[0] || "")
		.replace(/-/g, " ")
		.replace(/^\w/, (c) => c.toUpperCase());
	const brand =
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		(hit.variants as Record<string, any>[])?.[0]?.attributes?.brand || "";
	return (
		<article className="border-gray-200 border-b py-2 last:border-b-0">
			<Link
				href={`/nl/product/${(hit.slug as Record<string, string>)[locale]}`}
				className="flex items-center space-x-2"
			>
				<div className="shrink-0">
					<Image
						width={48}
						height={48}
						src={hit.image as string}
						alt={name}
						className="size-12 rounded-md object-cover"
					/>
				</div>
				<div className="grow">
					<h3
						className={cn(typography("small"), "font-semibold text-gray-900")}
					>
						{name}
					</h3>
					<p className={cn(typography("muted"), "text-gray-500")}>
						{brand} | {categories}
					</p>
					<p className={cn(typography("small"), "font-semibold text-gray-900")}>
						<Price money={hit.startingPrice as PriceFragmentFragment} />
					</p>
				</div>
			</Link>
		</article>
	);
}
function ContentHitComponent({ hit }: { hit: Hit }) {
	const locale = useLocale();
	const title = (hit.title as Record<string, string>)?.[locale] || "";
	const description =
		(hit.description as Record<string, string>)?.[locale] || "";
	if (!hit.fields?.searchContents) {
		return null;
	}
	return (
		<article className="border-gray-200 border-b py-2 last:border-b-0">
			<Link
				href={`/nl/${hit.fields?.slug?.["en-GB"] || ""}`}
				className="flex flex-col space-y-1"
			>
				<h3 className={cn(typography("small"), "font-semibold text-gray-900")}>
					<Highlight
						attribute="fields.name.en-GB"
						hit={hit}
						highlightedTagName="strong"
					/>
				</h3>
				{hit.fields.searchContents ? (
					<p className={cn(typography("muted"), "text-gray-500")}>
						<Highlight attribute="fields.searchContents.en-GB" hit={hit} />
					</p>
				) : null}
			</Link>
		</article>
	);
}
function Suggestion({ hit, ...props }: { hit: Hit; onClick: () => void }) {
	return (
		<button
			className="w-full cursor-pointer p-1 text-left hover:bg-gray-100"
			onClick={props.onClick}
			onKeyDown={(event) => {
				if (event.key === "Enter") {
					props.onClick();
				}
			}}
			type="button"
		>
			<Highlight attribute="query" hit={hit} highlightedTagName="strong" />
		</button>
	);
}
function CustomHierarchicalMenu({ attributes }: { attributes: string[] }) {
	const { items, refine } = useHierarchicalMenu({ attributes });
	return (
		<ul className="space-y-1 text-sm">
			{items.map((item) => (
				<li key={item.label} className="flex items-center space-x-1">
					<input
						type="checkbox"
						checked={item.isRefined}
						onChange={() => refine(item.value)}
						className="form-checkbox size-3 text-blue-600"
					/>
					<label
						className={cn(
							"cursor-pointer",
							item.isRefined ? "font-semibold" : "",
						)}
						onClick={() => refine(item.value)}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								refine(item.value);
							}
						}}
					>
						{item.label
							.replace(/-/g, " ")
							.replace(/^\w/, (char) => char.toUpperCase())}{" "}
						({item.count})
					</label>
				</li>
			))}
		</ul>
	);
}
function CustomPagination() {
	const {
		pages,
		currentRefinement,
		nbPages,
		isFirstPage,
		isLastPage,
		refine,
		createURL,
	} = usePagination();
	return (
		<ul className="flex items-center justify-center space-x-1 py-2 text-sm">
			<li>
				<button
					type="button"
					className={cn(
						"rounded px-2 py-1",
						isFirstPage
							? "cursor-not-allowed text-gray-400"
							: "text-blue-600 hover:bg-blue-100",
					)}
					onClick={() => refine(currentRefinement - 1)}
					disabled={isFirstPage}
				>
					&laquo;
				</button>
			</li>
			{pages.map((page) => (
				<li key={page}>
					<button
						type="button"
						className={cn(
							"rounded px-2 py-1",
							currentRefinement === page
								? "bg-blue-600 text-white"
								: "text-blue-600 hover:bg-blue-100",
						)}
						onClick={() => refine(page)}
					>
						{page + 1}
					</button>
				</li>
			))}
			<li>
				<button
					type="button"
					className={cn(
						"rounded px-2 py-1",
						isLastPage
							? "cursor-not-allowed text-gray-400"
							: "text-blue-600 hover:bg-blue-100",
					)}
					onClick={() => refine(currentRefinement + 1)}
					disabled={isLastPage}
				>
					&raquo;
				</button>
			</li>
		</ul>
	);
}
function SearchResults() {
	const { query, clear, refine } = useSearchBox();
	const [currentPathname, setCurrentPathname] = useState(usePathname());
	const pathname = usePathname();
	const [activeTab, setActiveTab] = useState<"products" | "content">(
		"products",
	);
	useEffect(() => {
		if (pathname !== currentPathname) {
			clear();
			setCurrentPathname(pathname);
		}
	}, [pathname]);
	if (!query) {
		return null;
	}
	return (
		<div className="absolute top-12 z-10 max-h-[80vh] w-full overflow-y-auto rounded-md border border-gray-200 bg-white shadow-lg">
			<div className="border-gray-200 border-b p-2">
				<h4 className={cn(typography("small"), "mb-1 font-semibold")}>
					Suggestions
				</h4>
				<Index indexName="nl_suggestions">
					<Hits
						hitComponent={(props) => (
							<Suggestion onClick={() => refine(props.hit.query)} {...props} />
						)}
					/>
				</Index>
			</div>
			<div className="flex border-gray-200 border-b">
				<button
					type="button"
					className={cn(
						"flex-1 px-4 py-2",
						activeTab === "products" ? "bg-gray-100 font-semibold" : "",
					)}
					onClick={() => setActiveTab("products")}
				>
					Products
				</button>
				<button
					type="button"
					className={cn(
						"flex-1 px-4 py-2",
						activeTab === "content" ? "bg-gray-100 font-semibold" : "",
					)}
					onClick={() => setActiveTab("content")}
				>
					Content
				</button>
			</div>
			{activeTab === "products" ? (
				<div className="flex">
					<div className="hidden w-1/3 border-gray-200 border-r p-2 md:block">
						<h4 className={cn(typography("small"), "mb-1 font-semibold")}>
							Categories
						</h4>
						<CustomHierarchicalMenu
							attributes={[
								"hierarchicalCategories.lvl0",
								"hierarchicalCategories.lvl1",
								"hierarchicalCategories.lvl2",
							]}
						/>
					</div>
					<div className="w-full p-2 md:w-2/3">
						<Index indexName="nl_default">
							<Configure hitsPerPage={5} />
							<Hits hitComponent={HitComponent} />
							<CustomPagination />
						</Index>
					</div>
				</div>
			) : (
				<div className="w-full p-2">
					<Index indexName="content">
						<Configure hitsPerPage={5} />
						<Hits hitComponent={ContentHitComponent} />
						<CustomPagination />
					</Index>
				</div>
			)}
		</div>
	);
}
export const AlgoliaSearch = () => {
	const t = useTranslations("SearchBar");
	return (
		<InstantSearch
			searchClient={searchClient}
			future={{ preserveSharedStateOnUnmount: true }}
			indexName="nl_default"
		>
			<div className="relative flex w-full flex-col">
				<SearchBox
					classNames={{
						root: "relative h-12 w-full",
						input:
							"h-12 w-full rounded-md border border-gray-300 bg-white px-4 py-2.5 shadow-sm disabled:cursor-not-allowed disabled:opacity-50",
						submit: "absolute right-0 top-0 m-0.5 h-12 rounded-full px-6",
						submitIcon: "size-4",
						reset: "absolute right-12 top-0 m-0.5 h-12 rounded-full px-6",
						resetIcon: "size-4",
					}}
					placeholder={t("placeholder")}
				/>
				<SearchResults />
			</div>
		</InstantSearch>
	);
};
