import { graphql } from "@evolve-storefront/types";
import type { ResultOf } from "@graphql-typed-document-node/core";
import type { ReactNode } from "react";
import type { HTMLAttributes } from "react";
import { formatMoney } from "~/lib/helpers";

export const PriceFragment = graphql(/* GraphQL */ `
	fragment PriceFragment on Money {
		centAmount
		currency
	}
`);

type Props = {
	money: ResultOf<typeof PriceFragment>;
	locale?: string;
} & HTMLAttributes<HTMLDivElement>;

export const Price = ({ money, locale, ...attributes }: Props): ReactNode => {
	return (
		<span {...attributes} aria-label="price">
			{formatMoney(money, locale)}
		</span>
	);
};
